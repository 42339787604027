<template>
  <div>
    <el-row>
      <el-col :span="4">
        <div>
          <el-menu
              :default-active="index"
              @select="handleSelect">
            <el-submenu index="1">
              <template slot="title">
                <i class="el-icon-menu"></i>
                <span>知识库</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="/interview/material">查阅资料</el-menu-item>
                <el-menu-item index="/interview/test">实力测验</el-menu-item>
                <el-menu-item index="/interview/testRecord">测验记录</el-menu-item>
                <el-menu-item index="/interview/wrongQuestionBank">错题记录</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
      </el-col>
      <el-col :span="16">
<!--        <Study v-if="index==='1-1'" />-->
<!--        <Test v-if="index==='1-2'" />-->
<!--        <TestRecord v-if="index==='1-3'" />-->
<!--        <WrongQuestionBank v-if="index==='1-4'" />-->
        <router-view />
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      index: '/interview/material'
    }
  },
  created() {
    this.handleSelect(window.location.pathname)
  },
  methods: {
    handleSelect(key) {
      this.index = key
      this.$router.push({ path: key })
    }
  }
}
</script>

<style scoped>

</style>
